import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Link } from "gatsby"
import cx from "classnames"
import styled from "styled-components"
import PropTypes from "prop-types"
import { getImpactSlugs } from "../../utils/general"
import SvgIcon from "../svg/SvgIcon"
import * as style from "./ImpactIcons.module.scss"

const DefaultButton = styled.button``

const ImpactIcons = ({
  slugString,
  columns,
  className,
  isSlugFormatted,
  onClick,
  ImpactButtonComponent,
  selectedImpacts,
}) => {
  const data = useStaticQuery(queryString)
  const impacts = data.allWpImpact.nodes
  const width = `${(1 / columns) * 100}%`
  const formattedSlug = isSlugFormatted ? slugString : getImpactSlugs(slugString)

  return slugString ? (
    <div className={cx(style.impactContainer, className)}>
      {formattedSlug.map(iconSlug => {
        const icon = impacts.find(impact => impact.slug === iconSlug)

        return (
          icon &&
          renderIcon({
            icon,
            width,
            onClick,
            ImpactButtonComponent,
            selectedImpacts,
          })
        )
      })}
    </div>
  ) : null
}

const renderIcon = ({ icon, width, onClick, ImpactButtonComponent, selectedImpacts }) => {
  const Button = ImpactButtonComponent || DefaultButton

  return (
    <div className={style.icon} style={{ width }} key={icon.slug}>
      {onClick ? (
        <Button onClick={() => onClick(icon.slug)} isSelected={selectedImpacts.includes(icon.slug)}>
          <SvgIcon svg={icon.icon.iconSvg} className={style.svg} />
        </Button>
      ) : (
        <Link to={`/impact/${icon.slug}`}>
          <SvgIcon svg={icon.icon.iconSvg} className={style.svg} />
        </Link>
      )}
    </div>
  )
}

ImpactIcons.propTypes = {
  slugString: PropTypes.string,
  columns: PropTypes.number,
  className: PropTypes.string,
}

const queryString = graphql`{
  allWpImpact {
    nodes {
      name
      slug
      icon {
        iconSvg
        icon {
          sourceUrl
          localFile {
            childImageSharp {
              gatsbyImageData(width: 300, placeholder: NONE, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
}
`

export default ImpactIcons

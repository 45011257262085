import * as React from "react"
import styled from "styled-components"
import { primaryDark, primaryLight } from "../../constants/colors"
import GLink from "../../../gatsby/GLink"
import * as colors from "../../constants/colors"

const Title = styled.h2`
  width: 100%;
  text-align: center;

  a {
    font-size: 0.5em;
    text-transform: none;
    color: ${colors.linkBlue};
    cursor: pointer;
  }
`

const Line = styled.hr`
  width: 100px;
  height: 2px;
  margin: 10px auto auto;
  color: ${primaryDark};
  background-color: ${primaryLight};
`

interface Props {
  title: string
  style?: object
  to?: string
  linkTitle?: string
}

const FeatureTitle = ({ title, style, to, linkTitle }: Props) => {
  return (
    <Title style={style}>
      {title} {to && <GLink to={to}>{linkTitle ? linkTitle : "See More"}</GLink>}
      <Line />
    </Title>
  )
}

export default FeatureTitle

import * as React from "react"
import { ReactNode } from "react"
import styled, { css } from "styled-components"
import PlainButton from "../button/PlainButton"
import { primaryLight, lightBlack } from "../../constants/colors"
import Plus from "../../../svg/plus.inline.svg"
import Minus from "../../../svg/minus.inline.svg"
import { screenSizes } from "@social-supermarket/social-supermarket-model"

const Container = styled.div`
  height: 75px;
  cursor: pointer;
`

const HeaderButton = styled(PlainButton)`
  width: 100%;
  height: 75px;
  padding: 0;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;

  &:hover {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.7);
  }
`

const ArrowContainer = styled.div`
  height: 19px;
`

const svgStiles = css`
  width: 19px;
  fill: ${primaryLight};
`

const StyledPlus = styled(Plus)`
  ${svgStiles}
`

const StyledMinus = styled(Minus)`
  ${svgStiles}
`

const HeaderText = styled.span`
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.71px;
  color: ${lightBlack};

  @media (max-width: ${screenSizes.smallMobile}px) {
    max-width: 305px;
  }
`

interface PropsType {
  children: ReactNode
  isOpen?: boolean
  onClick?: () => void
}

const AccordionHeader = ({ children, isOpen, onClick }: PropsType) => {
  return (
    <Container>
      <HeaderButton onClick={onClick}>
        <HeaderText>{children}</HeaderText>
        <ArrowContainer>{isOpen ? <StyledMinus /> : <StyledPlus />}</ArrowContainer>
      </HeaderButton>
    </Container>
  )
}

export default AccordionHeader

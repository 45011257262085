import * as React from "react"
import { useObjectQueryString } from "../../hooks/useQueryString"

interface PropsType {
  children: React.ReactElement[]
}

const Accordion = ({ children }: PropsType) => {
  const [openIndexs, setOpenIndexs] = useObjectQueryString("accordion", {}, true)
  const handleClick = i => () =>
    setOpenIndexs({
      ...openIndexs,
      [`${i}`]: !openIndexs[`${i}`],
    })

  let index = 0
  const childrenWithProps = React.Children.map(children, child => {
    return React.cloneElement(child, {
      isOpen: openIndexs[`${index}`],
      onClick: handleClick(index++),
    })
  })
  return <>{childrenWithProps}</>
}

export default Accordion

import React from "react"
import ProductPage from "../social-supermarket/pages/product/ProductPage"
import { graphql } from "gatsby"

const ProductTemplate = ({ data: { product } }) => {
  return <ProductPage product={product} />
}

export default ProductTemplate

export const query = graphql`
  query ($id: String!) {
    product(id: { eq: $id }) {
      ...DomainProduct
    }
  }
`

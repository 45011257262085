import * as React from "react"
import styled from "styled-components"
import DatePicker from "react-datepicker"

const Container = styled.div``
const Option = styled.div`
  margin-bottom: 10px;

  label {
    display: block;
    text-transform: uppercase;
    font-size: 0.8em;
    font-weight: bold;
    margin-bottom: 5px;
  }

  select,
  input,
  textarea {
    width: 100%;
  }

  textarea {
    height: 200px;
  }

  select {
    background: none;
  }
`

interface PropsType {
  value: object
  onChange: (event: any) => void
}
const GiftCardAttributeFields = ({ value, onChange }: PropsType) => {
  return (
    <Container>
      <Option>
        <label>Your Name</label>
        <input
          type="text"
          name="giftCardName"
          onChange={onChange}
          value={value["giftCardName"] || ""}
          required
        />
      </Option>
      <Option>
        <label>Recipient Email Address</label>
        <input
          type="text"
          name="giftCardEmail"
          onChange={onChange}
          value={value["giftCardEmail"] || ""}
          required
        />
      </Option>
      <Option>
        <label>Schedule</label>
        <select
          name="giftCardSchedule"
          onChange={onChange}
          value={value["giftCardSchedule"] || ""}
          required
        >
          <option disabled value="">
            Select a scheduling option
          </option>
          <option value="now">Email the recipient immediately</option>
          <option value="schedule">Schedule a delivery date</option>
        </select>
      </Option>
      {value["giftCardSchedule"] === "schedule" && (
        <Option>
          <label>Delivery Date</label>
          <DatePicker
            selected={value["giftCardDate"] || ""}
            onChange={date => onChange({ target: { name: "giftCardDate", value: date } })}
            style={{ width: "100%" }}
            dateFormat="dd/MM/yyyy"
            required
          />
        </Option>
      )}
      <Option>
        <label>Gift Note</label>
        <textarea
          name="giftCardNote"
          onChange={onChange}
          value={value["giftCardNote"] || ""}
          required
        />
      </Option>
    </Container>
  )
}

export default GiftCardAttributeFields

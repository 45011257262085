import * as React from "react"
import styled from "styled-components"
import { lightBlack } from "../../constants/colors"

const Container = styled.div`
  padding-bottom: 25px;
`

const Content = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${lightBlack};
`

const AccordionBody = ({ children }) => {
  return (
    <Container>
      <Content>{children}</Content>
    </Container>
  )
}

export default AccordionBody

import * as React from "react"
// @ts-ignore
import Arrow from "../../../svg/arrow.inline.svg"
import styled from "styled-components"
import { primaryLight } from "../../constants/colors"
import GLink from "../../../gatsby/GLink"

const Button = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: underline;
  font-size: 0.9em;

  svg {
    width: 50px;
    margin-right: 10px;
    fill: ${({ fillColor }) => fillColor};
  }

  &:hover {
    text-decoration: underline;

    svg {
      animation-name: bounce-5;
      animation-timing-function: ease;
      animation-duration: 0.4s;
      animation-iteration-count: 1;
    }
  }

  &:focus {
    border: none !important;
    outline: none;
  }

  @keyframes bounce-5 {
    10% {
      transform: scale(1, 1) translateX(5px);
    }
    30% {
      transform: scale(1.2, 1) translateX(10px);
    }
  }
`

const LinkContainer = styled.div`
  width: 100%;

  background: none;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: underline;
  font-size: 0.9em;

  a {
    display: block;
    width: 100%;
    display: flex;
    align-items: center;

    &:hover {
      color: black;
    }
  }

  svg {
    width: 50px;
    margin-right: 10px;
    fill: ${({ fillColor }) => fillColor};
  }

  &:hover {
    text-decoration: underline;

    svg {
      animation-name: bounce-5;
      animation-timing-function: ease;
      animation-duration: 0.4s;
      animation-iteration-count: 1;
    }
  }

  &:focus {
    border: none !important;
    outline: none;
  }

  @keyframes bounce-5 {
    10% {
      transform: scale(1, 1) translateX(5px);
    }
    30% {
      transform: scale(1.2, 1) translateX(10px);
    }
  }
`

interface PropsType {
  onClick?: () => void
  to?: string
  style?: object
  children: React.ReactNode
  fillColor?: string
}

const IconLinkButton = ({ onClick, to, children, style, fillColor = primaryLight }: PropsType) => {
  return to ? (
    <LinkContainer style={style} fillColor={fillColor}>
      <GLink to={to} onClick={onClick}>
        <Arrow />
        {children}
      </GLink>
    </LinkContainer>
  ) : (
    <Button style={style} onClick={onClick} fillColor={fillColor}>
      <Arrow />
      {children}
    </Button>
  )
}

export default IconLinkButton

import * as React from "react"
import styled from "styled-components"
import { FC, useRef, useState } from "react"
import LinkButton from "../../components/button/LinkButton"

const Container = styled.div`
  height: ${({ fullHeight, showMore }) =>
    showMore ? fullHeight + 50 : fullHeight < 300 ? fullHeight : "300"}px;
  overflow: hidden;
  position: relative;
  transition: all 0.5s ease-in-out;
  margin-bottom: 20px;
`

const ShowMoreButton = styled(LinkButton)``

const ShowMoreContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
  background-image: linear-gradient(180deg, rgb(0, 0, 0, 0), white);
`
const FadeContainer = styled.div`
  height: 30%;
  background-image: linear-gradient(180deg, rgb(0, 0, 0, 0), white);
`
const WhiteContainer = styled.div`
  background-color: white;
  height: 70%;
  display: flex;
  align-items: center;
`
const Content = styled.div`
  padding-bottom: 10px;
`

interface Props {
  shortDescription: string
}

const ProductShortDescription: FC<Props> = ({ shortDescription }) => {
  const [showMore, setShowMore] = useState<boolean>(false)
  const ref = useRef(null)
  const needsShowMore = ref.current?.offsetHeight > 300

  const handleToggle = () => {
    setShowMore(!showMore)
    if (showMore) {
      window.scrollTo({ top: 300, left: 0, behavior: "smooth" })
    }
  }
  return (
    <Container fullHeight={ref.current?.offsetHeight} showMore={showMore}>
      <Content ref={ref} dangerouslySetInnerHTML={{ __html: shortDescription }} />
      {needsShowMore && (
        <ShowMoreContainer>
          <FadeContainer />
          <WhiteContainer>
            <ShowMoreButton onClick={handleToggle}>
              {showMore ? "Show Less" : "Show More"}
            </ShowMoreButton>
          </WhiteContainer>
        </ShowMoreContainer>
      )}
    </Container>
  )
}

export default ProductShortDescription
